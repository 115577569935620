const seedRandom = (seed) => {
  let m = 0x80000000; // 2**31
  let a = 1103515245;
  let c = 12345;

  let state = seed ? seed : Math.floor(Math.random() * (m - 1));

  return () => {
    state = (a * state + c) % m;
    return state / (m - 1);
  };
};

export const shuffleArray = (array) => {
  const random = seedRandom(array.length);
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};
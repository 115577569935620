import React, { useState, Fragment, useEffect } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Fuse from 'fuse.js'
import TutorialTiles from './components/TutorialTiles'
import { FaSearch } from 'react-icons/fa'
import FilterComponent from './components/filters'
import HeroBanner from './components/HeroBanner'
import LearnMoreComponent from './components/LearnMore'
import {
  getQueryParams,
  useQueryParamString,
} from 'react-use-query-param-string'
import { shuffleArray } from '../utils/shuffleArray'

const Tutorials = ({ data }) => {
  const [, setSelectedLanguagesParam] = useQueryParamString('languages', '')
  const [selectedLanguages, setSelectedLanguages] = useState(
    Array.isArray(getQueryParams().languages)
      ? getQueryParams().languages
      : getQueryParams().languages
      ? [getQueryParams().languages]
      : []
  )
  const [input, setInput] = useState('')
  const [, setSelectedTagsParam] = useQueryParamString('tags', '')
  const [selectedTags, setSelectedTags] = useState(
    Array.isArray(getQueryParams().tags)
      ? getQueryParams().tags
      : getQueryParams().tags
      ? [getQueryParams().tags]
      : []
  )
  const [selectedTechnology, setSelectedTechnology] = useQueryParamString(
    'technology',
    ''
  )
  const [selectedContentType, setSelectedContentType] = useQueryParamString(
    'type',
    ''
  )
  const [allTutorials, setAllTutorials] = useState([])

  const [currentPage, setCurrentPage] = useState(1)
  const tutorialsPerPage = 12

  useEffect(() => {
    setAllTutorials(shuffleArray([...data.tutorials.nodes]))
  }, [])

  const handleSelectedLanguages = (v) => {
    setSelectedLanguages(v)
    setSelectedLanguagesParam(v)
  }

  const onSelectContentType = (_value) => {
    setSelectedContentType(_value)
  }

  const fuseOptions = {
    keys: [
      'frontmatter.title',
      'frontmatter.short_title',
      'frontmatter.description',
      'frontmatter.tags',
      'frontmatter.technology',
      'frontmatter.sdk_language'
    ],
    threshold: 0.3
  }

  useEffect(() => { 
    const fuse = new Fuse([...data.tutorials.nodes], fuseOptions)
    const filteredTutorials = input
    ? fuse.search(input).map(result => result.item)
    : shuffleArray([...data.tutorials.nodes])
    setAllTutorials(filteredTutorials)
  }, [input])

  let filteredContent = allTutorials

  if (selectedLanguages && selectedLanguages.length) {
    filteredContent = filteredContent.filter((content_item) => {
      if (content_item.frontmatter.sdk_language !== null) {
        return (
          content_item.frontmatter.sdk_language.filter((value) =>
            selectedLanguages.some((lang) => value.includes(lang))
          ).length > 0
        )
      }
      return ''
    })
  }

  if (selectedTags.length > 0) {
    filteredContent = filteredContent.filter((content_item) => {
      if (content_item.frontmatter.tags !== null) {
        return (
          content_item.frontmatter.tags.filter((value) =>
            selectedTags.includes(value)
          ).length > 0
        )
      }
      return ''
    })
  }

  let tech = filteredContent.map((content) => content.frontmatter.technology)
  const contentLength = filteredContent.length

  if (selectedTechnology.length > 0) {
    filteredContent = filteredContent.filter((content_item) => {
      if (content_item.frontmatter.technology !== null) {
        return (
          content_item.frontmatter.technology.filter((value) =>
            selectedTechnology.includes(value)
          ).length > 0
        )
      }
      return ''
    })
  }

  const tutorials = filteredContent.filter(
    (content_item) => content_item.frontmatter.content_type === 'tutorial'
  )
  const learningPaths = filteredContent.filter(
    (content_item) => content_item.frontmatter.content_type === 'learn'
  )
  const quickstarts = filteredContent.filter(
    (content_item) => content_item.frontmatter.content_type === 'quickstart'
  )

  let searchedContent = [...filteredContent]
  if (selectedContentType === 'Quickstart Guides') {
    searchedContent = quickstarts
  }

  if (selectedContentType === 'Tutorials') {
    searchedContent = tutorials
  }

  if (selectedContentType === 'Learning Paths') {
    searchedContent = learningPaths
  }


  const clearSearch = () => {
    setInput('')

    setSelectedLanguages([])
    setSelectedLanguagesParam('')

    setSelectedTags([])
    setSelectedTagsParam('')

    setSelectedTechnology('')
    setSelectedContentType('')
  }


  // Get AI Tutorials
  let allTutorialsFrontmatter = [...data.tutorials.nodes].map((node) => node.frontmatter)
  const aiTutorials = allTutorialsFrontmatter.filter((tutorial) =>
    tutorial.tags.includes('Artificial Intelligence')
  )

  // randomize the order of the AI tutorials and keep only 6
  aiTutorials.sort(() => Math.random() - 0.5)
  aiTutorials.length = 6

  // Pagination
  // Calculate indexes
  const indexOfLastTutorial = currentPage * tutorialsPerPage
  const indexOfFirstTutorial = indexOfLastTutorial - tutorialsPerPage
  const currentContent = searchedContent.slice(
    indexOfFirstTutorial,
    indexOfLastTutorial
  )

  // Calculate total pages
  const totalPages = Math.ceil(searchedContent.length / tutorialsPerPage)

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(1)
    }
  }, [currentPage, totalPages])

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber)
    document
      .getElementById('cb-tutorials-container-header')
      .scrollIntoView({ behavior: 'smooth' })
  }

  // Previous page
  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
      document
        .getElementById('cb-tutorials-container-header')
        .scrollIntoView({ behavior: 'smooth' })
    }
  }

  // Next page
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
      document
        .getElementById('cb-tutorials-container-header')
        .scrollIntoView({ behavior: 'smooth' })
    }
  }

  const allFilters = [input, selectedContentType, ...selectedLanguages, ...selectedTags, selectedTechnology]


  return (
    <Fragment>
      <HeroBanner tutorials={aiTutorials} />
      <div className="page--tutorials wrapper mb-24 px-7">
        <Helmet title={`Tutorials | Couchbase Developer Portal`}>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no user-scalable=no"
          />
        </Helmet>
        <div
          className="pt-4 xl:pt-8 pb-12 text-center wrapper"
          id="cb-tutorials-container-header"
        >
          <h2>Learn with Couchbase Tutorials</h2>
          <p>
            The tutorials and learning paths available cover a wide range of
            topics related to Couchbase. These topics include CRUD operations,
            SQL querying, transactions, building REST APIs, and more.
          </p>
        </div>
        <div className="search-wrapper">
          <input
            className="search-input"
            placeholder="Search..."
            name="input"
            value={input}
            onChange={(event) => setInput(event.target.value)}
          />
          <FaSearch className="search-icon" />
        </div>
        <div className="filter-accordions flex justify-between flex-wrap">
          <FilterComponent
            selectedLanguages={selectedLanguages}
            setSelectedLanguages={handleSelectedLanguages}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTagsParam}
            selectedTechnology={selectedTechnology}
            setSelectedTechnology={setSelectedTechnology}
            selectedContentType={selectedContentType}
            onSelectContentType={onSelectContentType}
            tech={tech}
            contentLength={contentLength}
            tutorials={tutorials}
            learningPaths={learningPaths}
            quickstarts={quickstarts}
          />
        </div>
        {input ||
        selectedLanguages.length ||
        selectedTags.length ||
        selectedTechnology.length ||
        selectedContentType.length ? (
          <div className="w-full lg:w-4/5 relative flex flex-wrap">
            {searchedContent.length} Results filtered by: 
            <div className='filter-tag-list inline'>
              {allFilters.map((filter) => {
                if (filter.length > 0) {
                  return (
                    <span key={filter} className="filter-tag mx-1">
                      {filter}
                    </span>
                  )
                }
                return ''
              })}
            </div>
            <div className="clear-tags" onClick={clearSearch}>
              Clear all filters
            </div>
          </div>
        ) : (
          ''
        )}
        <div className="flex flex-row">
          <div className="w-full">
            {searchedContent.length === 0 ? (
              <p className="pt-8 pb-20">
                There are no results found in the category
              </p>
            ) : (
              ''
            )}
            {searchedContent.length > 0 && (
              <Fragment>
                {currentContent.length ? (
                  <Fragment>
                    {/* <div className="tutorial-heading mt-10 mb-10">
                      <div
                        className="text-2xl font-semibold"
                        style={{ display: 'inline-block' }}
                        id="tutorials-heading"
                      >
                        Tutorials
                      </div>
                    </div> */}
                    <div className="tutorial-section">
                      <div className={'grid-tiles-container-tutorials'}>
                        {currentContent.map((tutorial, idx) => {
                          return <TutorialTiles tutorial={tutorial} idx={currentPage* tutorialsPerPage + idx}/>
                        })}
                      </div>
                    </div>

                    <div className="pagination">
                      <button
                        onClick={previousPage}
                        disabled={currentPage === 1}
                        className={`pagination-button ${
                          currentPage === 1 ? 'hidden' : ''
                        }`}
                      >
                        &lt; Previous
                      </button>

                      {totalPages > 3 ? (
                        <>
                          {currentPage > 2 && (
                            <button
                              key={1}
                              onClick={() => paginate(1)}
                              className={`pagination-button ${
                                currentPage === 1 ? 'active' : ''
                              }`}
                            >
                              1
                            </button>
                          )}
                          {currentPage > 2 && (
                            <span className="pagination-ellipsis">...</span>
                          )}
                          {[...Array(totalPages)].map((_, index) => {
                            if (
                              index + 1 === currentPage ||
                              index + 1 === currentPage - 1 ||
                              index + 1 === currentPage + 1
                            ) {
                              return (
                                <button
                                  key={index + 1}
                                  onClick={() => paginate(index + 1)}
                                  className={`pagination-button ${
                                    currentPage === index + 1 ? 'active' : ''
                                  }`}
                                >
                                  {index + 1}
                                </button>
                              )
                            }
                            return null
                          })}
                          {currentPage < totalPages - 1 && (
                            <span className="pagination-ellipsis">...</span>
                          )}
                          {currentPage < totalPages - 1 && (
                            <button
                              key={totalPages}
                              onClick={() => paginate(totalPages)}
                              className={`pagination-button ${
                                currentPage === totalPages ? 'active' : ''
                              }`}
                            >
                              {totalPages}
                            </button>
                          )}
                        </>
                      ) : (
                        [...Array(totalPages)].map((_, index) => (
                          <button
                            key={index + 1}
                            onClick={() => paginate(index + 1)}
                            className={`pagination-button ${
                              currentPage === index + 1 ? 'active' : ''
                            }`}
                          >
                            {index + 1}
                          </button>
                        ))
                      )}

                      <button
                        onClick={nextPage}
                        disabled={currentPage === totalPages}
                        className={`pagination-button ${
                          currentPage === totalPages ? 'hidden' : ''
                        }`}
                      >
                        Next &gt;
                      </button>
                    </div>
                  </Fragment>
                ) : (
                  ''
                )}
              </Fragment>
            )}
          </div>
        </div>
      </div>
      <LearnMoreComponent />
    </Fragment>
  )
}

export default Tutorials

// Video on using gatsby-plugin-image: https://www.youtube.com/watch?v=zRtFwzF4p1o
// import style module to style this page
// sorting: https://www.youtube.com/watch?v=G3KkHk8iSOg
// multi querys: https://www.youtube.com/watch?v=WmSJMj-gNKo

export const query = graphql`
  query tutorialsQuery {
    tutorials: allMarkdownRemark(
      filter: {
        frontmatter: {
          content_type: { in: ["tutorial", "learn", "quickstart"] }
          exclude_tutorials: { in: [null, false] }
        }
      }
    ) {
      nodes {
        frontmatter {
          path
          content_type
          title
          short_title
          technology
          filter
          description
          length
          tags
          sdk_language
          exclude_tutorials
        }
        id
      }
    }
  }
`

import React, { Fragment } from "react";

const LearnMoreComponent = () => {
    return (
        <Fragment>
            <div className=" learn-more-section">
                <div className="page--tutorials wrapper px-8">
                    <div className='pt-8 text-start pl-0'>
                        <h2 className="text-white">Couchbase Developer Community</h2>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-16 place-items-start">
                        <div className="text-white my-10">
                            <div className="text-lg font-bold leading-6 mb-3.5">Community Help</div>
                            <div className="text-base font-normal leading-6 pb-5 mb-5">
                                In addition to the <a href="https://support.couchbase.com/hc/en-us" className="yellow-link"> Couchbase Support Team</a>,
                                help can be found from the community in our <a href="https://www.couchbase.com/forums/" className="yellow-link">forums</a>,
                                and our official <a href="https://discord.gg/jJfT4pDU6z" className="yellow-link">Couchbase Discord Server</a>.
                            </div>
                            <a href="https://www.couchbase.com/developers/community/"
                                className="learn-more-btn">Learn More
                            </a>
                        </div>
                        <div className="text-white my-10">
                            <div className="text-lg font-bold leading-6 mb-3.5">Integrations</div>
                            <div className="text-base font-normal leading-6 pb-5 mb-5">
                                Integrations on some 3rd-party SDK integrations, such as Spring Data, can be found in the SDK docs.
                            </div>
                            <a href="https://couchbase.com/developers/integrations/"
                                className="learn-more-btn">Learn more
                            </a>
                        </div>
                        <div className="text-white my-10">
                            <div className="text-lg font-bold leading-6 mb-3.5">Get certified with Couchbase Academy</div>
                            <div className="text-base font-normal leading-6 pb-5 mb-5">
                                Whether you’re managing Couchbase on premises, using CAO, using Couchbase Capella, or writing apps that use Couchbase, we have a certification for you.
                            </div>
                            <a href="https://www.couchbase.com/academy"
                                className="learn-more-btn">Get Certified
                            </a>
                        </div>
                        <div className="text-white my-10">
                            <div className="text-lg font-bold leading-6 mb-3.5">Stay sharp with our blog</div>
                            <div className="text-base font-normal leading-6 pb-5 mb-5">
                                News breaks first on our blog. Stay up to date on the Couchbase ecosystem and learn tips and tricks from our engineers, developer advocates, and partners.
                            </div>
                            <a href="https://www.couchbase.com/blog/"
                                className="learn-more-btn">Subscribe To Our Blog
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default LearnMoreComponent;
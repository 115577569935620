import React from 'react'
import { Link } from 'gatsby'
import '../../styles/modules/tutorials.scss'

const TutorialTiles = ({ tutorial, idx }) => {
  if (!tutorial || !tutorial.frontmatter) {
    return null
  }

  return (
    <Link to={tutorial.frontmatter.path} key={idx} className="inline-block">
      <div className={`grid-tile-item ${idx}`} key={idx}>
        <div className="tile-item--title">{tutorial.frontmatter.title}</div>
        <div className="tile-item-description">
          <ul>
            {tutorial.frontmatter.description.map((descriptionBullet, index) => {
              return <li key={index}>{descriptionBullet}</li>
            })}
          </ul>
        </div>
        <div className="tile-item--tags">
          {tutorial.frontmatter.tags.map((value, index) => (
            <div className="tags--tag" key={`${value}-${index}`}>
              {value}
            </div>
          ))}
        </div>
      </div>
    </Link>
  )
}

export default TutorialTiles

import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Link } from 'gatsby'

const CustomArrow = ({ onClick, isNext }) => {
  return (
    <button
      onClick={onClick}
      className={`w-10 h-10 flex items-center justify-center rounded-full transition-colors z-2 carousel-arrow ${
        isNext ? ' carousel-next' : ' carousel-prev'
      }`}
      aria-label="Previous slide"
    >
      <svg
        width="14"
        height="27"
        viewBox="0 0 14 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13 1.43994L1 13.4399L13 25.4399"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
  )
}

const HeroBanner = ({ tutorials }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    prevArrow: <CustomArrow isNext={false} />,
    nextArrow: <CustomArrow isNext={true} />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <>
      <div className="banner-section">
        <div className="max-w-7xl m-auto flex flex-wrap">
          <div className="masthead">
            <div className="title">Couchbase Developer Tutorials</div>
            <div className="text-lg font-light">
              Tutorials and resources to help developers learn how to use
              Couchbase's SDKs, Capella, and Couchbase Mobile. These SDKs
              support popular programming languages, including Java, Node.js,
              Python, .NET, Golang, as well as Swift, Android, Kotlin, and more.
            </div>
          </div>
          <div className="flex justify-center align-center w-full">
            <div className="ml-12 featured-img-wrap"></div>
          </div>
        </div>

        {tutorials && tutorials.length > 0 && (
          <section className="relative py-16 xl:pb-20 z-10 mt-16 xl:mt-4">
            <div className="container px-4 mx-auto flex justify-center">
              <h2 className="mb-6 text-center text-2xl font-bold text-black">
                Suggested Tutorials
              </h2>

              <Slider {...settings}>
                {tutorials.map((tutorial, index) => (
                  <Link
                    to={`${tutorial.path}`}
                    key={index}
                    className="inline-block p-4 h-full"
                  >
                    <div className="tutorial-card h-full bg-transparent p-6 text-black border border-black rounded-lg flex flex-col">
                      <h3 className="mb-4 text-xl font-semibold">
                        {tutorial.short_title}
                      </h3>
                      <p className="mb-6 text-black text-300 flex-grow">
                        {tutorial.title}
                      </p>
                      <div className="flex flex-wrap gap-2 mt-auto">
                        {tutorial.tags.map((tag, tagIndex) => (
                          <span
                            key={tagIndex}
                            className="rounded-sm px-3 py-1 text-xs font-medium text-white uppercase tag-bg"
                          >
                            {tag}
                          </span>
                        ))}
                      </div>
                    </div>
                  </Link>
                ))}
              </Slider>
            </div>
          </section>
        )}
      </div>
      <div className="banner-black-container">
        <div className="banner-black-bg"></div>
      </div>
    </>
  )
}

export default HeroBanner
